import * as React from 'react'
import { Card, Box, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import './PostThumbItem.css'
const PostThumbItem = (props) => {
    const [isHover, setIsHover] = React.useState(false)
    const navigate = useNavigate()
  return (
    <div className="post-thumb-item" onMouseOver={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}>
    <Card style={{width:"100%",aspectRatio:"4 / 3", position:"relative"}} >
        {isHover && <Box style={{display:"block", width:"100%", position:"absolute", top:0,left:0,padding:"0.5rem",fontWeight:"bold", zIndex:1,color:"#006400", backgroundColor:"rgba(255,255,255,0.85"}}>
                {props.live && <RemoveRedEyeIcon  sx={{marginRight:"0.25rem"}} onClick={()=>navigate(`../${props.route}/${props.id}`)}/>}
                <EditIcon sx={{marginRight:"0.25rem"}} onClick={()=>navigate(`../dashboard/${props.route}/${props.id}`)}/>
            <p>{props.title}</p>
            </Box>}
        <img style={{display:"block", zIndex:0, height:"100%", width:"100%", objectFit:"cover"}} src={`https://api.djk-roden.de/uploads/images/${props.img}`} alt={props.title}/>
    </Card>
    </div>
  )
}

export default PostThumbItem