import React from 'react';
import './PostGrid.css';
import PostGridItemLarge from './PostGridItemLarge';
import PostGridItemSmall from './PostGridItemSmall';
import Button from '../../shared/components/FormElements/Button';
import { Grid } from '@mui/material';
const PostGrid = props => {
    const createCardsLG =(data,index)=>{
        return(<PostGridItemLarge
        key={index}
        content={data.content}
                date={data.createdAt}
                image={data.image}
                category={data.category.title}
                id={data.id}
                title={data.title}
                author={data.creator.name}
                sm={4}
                md={4}
                lg={4}
        />
        )
    }

    const createCardsSM =(data,index) =>{
        return(
            <PostGridItemSmall
            key={index}
                title={data.title}
                id={data.id}
                date={data.createdAt}
                image={data.image}
                author={data.creator.name}
                category={data.category.title}
                content={data.content}
            />
        )
    }
  
    return(<Grid container spacing={2} >
              <Grid item sm={9}>      
              <Grid container spacing={2}>
          {props.items.slice(0,3).map(createCardsLG)}
        </Grid>
        </Grid>
        <Grid item sm={3}>
        <Grid container spacing={2}>
        {props.items.slice(3,5).map(createCardsSM)}
        <Grid item sx={12}><Button to="/news">Mehr News</Button></Grid>
        </Grid>
        </Grid>
        </Grid>)
}

export default PostGrid;

