import React, { useContext,useState } from 'react';
import Card from '../../shared/components/UIElements/Card';
import { Grid, Stack, Divider } from '@mui/material';
import './TeamGridItem.css';
import {Link, useNavigate} from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import AlarmIcon from '@mui/icons-material/Alarm';
import FeedIcon from '@mui/icons-material/Feed';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Button from '../../shared/components/FormElements/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '../../shared/components/UIElements/Modal';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
const alldays = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag"
  ];
  
const TeamGridItem = (props) => {
    const auth = useContext(AuthContext)
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const {isLoading, error, sendRequest, clearError} = useHttpClient();


    const showDeleteWarningHandler = () => {
      setShowConfirmModal(true);
    };
  
    const cancelDeleteHandler = () => {
      setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);
        try {
          sendRequest(`https://api.djk-roden.de/api/teams/${props.id}`, 'DELETE',null, {Authorization: 'Bearer ' + auth.token});
          props.onDelete(props.id);
        } catch (error) {
          
        }
      };
         
    const navigate = useNavigate();
    const url = "https://api.djk-roden.de/uploads/images";
    const CardBGstyle = {
    backgroundImage: "url(" + url +  `/${props.image})`,
    backgroundSize: "cover",
    filter: "contrast(1.25)",
    backgroundPosition:"center center",
    borderRadius:"8px 8px 0 0",
    paddingTop:"66%"    

    }

    const navigateHandler = () => {
        navigate(`../mannschaften/${props.status.toLowerCase()}/${props.id}`);
        window.scroll(0, 0);
        document.location.reload();

    }

    return(
        <>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner />}
      
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Bist du sicher?"
        footerClass="team-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              ZURÜCK
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              LÖSCHEN
            </Button>
          </React.Fragment>
        }
      >
        <p>
        Möchtest du fortfahren und dieses Team löschen?
        Bitte beachte, dass dieser Vorgang
        danach nicht mehr rückgängig gemacht werden kann.
        </p>
      </Modal>

        <Grid item xs={12} sm={12} md={6} lg={auth.isLoggedIn ? 4 : 3}>
            <Card className="team-grid__card">
            <div className="team-grid__card-img" onClick={navigateHandler} style={CardBGstyle}></div>
            <div className="team-grid__card-container">
            <div className="team-grid__card-content-container">
                <h3 className="team-grid__card-title">{props.title}</h3>
                <Stack sx={{margin:"1rem 0"}} direction="row" justifyContent="center" spacing={1} divider={<Divider orientation="vertical" flexItem />} >
                <div style={{display:"flex",alignItems:"center",gap:3}}>
                    {(props.gender === "Gemischt" || props.gender === "Weiblich")  && <FemaleIcon style={{color:"#006400"}}/>}
                    {(props.gender ===  "Gemischt" || props.gender === "Männlich") && <MaleIcon style={{color:"#006400"}}/>}
                </div>
                <div style={{display:"flex",alignItems:"center",gap:3}}>
                    <GroupIcon style={{color:"#006400"}}/>{props.players?.length}
                </div>
                <div style={{display:"flex",alignItems:"center",gap:3}}><FeedIcon style={{color:"#006400"}}/>{props.reports?.length}</div>
                <div style={{display:"flex",alignItems:"center",gap:3}}><AlarmIcon style={{color:"#006400"}}/>{props.trainings?.sort((a,b) => alldays.indexOf(a.day) - alldays.indexOf(b.day)).map(t => <span style={{fontSize:"0.75rem", fontWeight:"bold"}}>{t.day.slice(0,2).toUpperCase()} </span>)}</div>
            </Stack>
                <p className="team-grid__card-content"><b>Team info: </b> {props.content}</p>
            </div>
            
            {!auth.isLoggedIn && <div className="team-grid__card-footer">
                <Link to={`../mannschaften/${props.status.toLowerCase()}/${props.id}`}>Mehr</Link>
                <span className="post-grid__card-date">{props.league}</span>
                
            </div>}
            {auth.isLoggedIn && <Stack sx={{marginTop:"1rem"}} justifyContent="center" direction="row">
            
              <Button to={`../teams/${props.id}`}><RemoveRedEyeIcon /></Button>
              <Button to={`../dashboard/teams/${props.id}`}><EditIcon /></Button>
              <Button danger onClick={showDeleteWarningHandler}><DeleteIcon /></Button>

            </Stack>}
            </div>
            </Card>
            </Grid>
            </>
    )
}

export default TeamGridItem; 