import React from 'react';

import './Card.css';

const Card = props => {
  return (
    <div id={props.id} className={`card ${props.className} ${props.noBorder && " borderless"}`} style={props.style}>
    {props.children}
    </div>
  );
};

export default Card;

