import * as React from 'react';
import Button from '../../shared/components/FormElements/Button';
import { Paper} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Edit from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import Modal from '../../shared/components/UIElements/Modal';

import './TrainingsList.css';


const TrainingsList = props => {
  const auth = React.useContext(AuthContext);
  const [selectionModel,setSelectionModel] = React.useState()
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const {isLoading, error, sendRequest, clearError} = useHttpClient();
  const navigate = useNavigate()
    const columns = [
      { field: 'id', headerName: 'ID', width: 70 },
      { field: 'day', headerName: 'Tag', width: 210 },
      { field: 'time', headerName: 'Uhrzeit', width: 210, valueGetter: e => `${e.row?.start} - ${e.row?.end}` },
      { field: 'location', headerName: 'Ort', width: 210 },
      { field: 'team', headerName: 'Team', width: 140, valueGetter: e => e.value?.name },
      { field: 'edit', headerName: 'Bearbeiten', width: 70, renderCell: cell => <Button id={cell.id} onClick={()=> navigate(`../dashboard/trainings/${cell.id}`)}><Edit fontSize='small' /></Button>},

      
    ]
   


    const showDeleteWarningHandler = () => {
      setShowConfirmModal(true);
    };
  
    const cancelDeleteHandler = () => {
      setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);
        try {
          for(let i = 0; i < selectionModel.length; i++){
          await sendRequest(`https://api.djk-roden.de/api/trainings/${selectionModel[i]}`, 'DELETE',null, {Authorization: 'Bearer ' + auth.token});
          props.onDelete(props.id);
          }
        } catch (error) {
          
        }
      };

    return(
        <>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner />}
      
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Bist du sicher?"
        footerClass="team-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              ZURÜCK
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              LÖSCHEN
            </Button>
          </React.Fragment>
        }
      >
        <p>
        Möchtest du fortfahren und {selectionModel?.length} {selectionModel?.length !== 1 ? "Trainings" : "Training"} löschen?
        Bitte beachte, dass dieser Vorgang
        danach nicht mehr rückgängig gemacht werden kann.
        </p>
      </Modal>

        {selectionModel?.length > 0 &&
        <Paper sx={{margin:"0.75rem 0",padding:"0.25rem 1rem", display:"flex", alignItems:"center", justifyContent:"flex-end", gap:2}}>
        <p>{selectionModel.length} {selectionModel.length !== 1 ? "Trainings" : "Training"} löschen</p>
        <Delete onClick={showDeleteWarningHandler}/>
        </Paper>}

        <Paper sx={{height:"380px", width:"100%"}}>
        <DataGrid
        rows={props.rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        experimentalFeatures={{ newEditingApi: true }}
        selectionModel={selectionModel}
        components={{
    Toolbar: GridToolbar,
  }}
        onSelectionModelChange={(e)=> setSelectionModel(e)}
        />
        </Paper>
        </>
       )
}

export default TrainingsList;