import * as React from 'react'
import { ListItem, IconButton, ListItemAvatar, ListItemText, Avatar, Link} from '@mui/material'
import Button from '../../shared/components/FormElements/Button'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { AuthContext } from '../../shared/context/auth-context';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useForm } from '../../shared/hooks/form-hook';
import Input from '../../shared/components/FormElements/Input';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const TickerListItem = (props) => {
   
    const auth = React.useContext(AuthContext);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const {isLoading, error, sendRequest, clearError} = useHttpClient();
    const [formState, inputHandler] = useForm({
        title:{
          value:props.title,
          isValid:true
        },
        link:{
            value:props.link,
            isValid:true
        }
      },true)

    const showDeleteWarningHandler = () => {
      setShowConfirmModal(true);
    };
    const showEditHandler = () => {
      setShowEditModal(true);
    };
  
    const cancelDeleteHandler = () => {
      setShowConfirmModal(false);
    };
    const cancelEditHandler = () => {
      setShowEditModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);
        try {
            console.log(props.id);
          sendRequest(`https://api.djk-roden.de/api/tickers/${props.id}`, 'DELETE',null, {Authorization: 'Bearer ' + auth.token});
          props.onDelete(props.id);
        } catch (error) {
          
        }
      };

      const confirmEditHandler = async () => {
        setShowConfirmModal(false);
        try {
        
          sendRequest(`https://api.djk-roden.de/api/tickers/${props.id}`, 'PATCH',JSON.stringify({title:formState.inputs.title.value, link:formState.inputs.link.value}), {Authorization: 'Bearer ' + auth.token});
          
        } catch (error) {
          
        }
      };
if(isLoading)return<LoadingSpinner />
return<>
  <ErrorModal error={error} onClear={clearError} />
      
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Bist du sicher?"
        footerClass="ticker-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              ZURÜCK
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              LÖSCHEN
            </Button>
          </React.Fragment>
        }
      >
        <p>
        Möchtest du fortfahren und diesen Ticker löschen?
        Bitte beachte, dass dieser Vorgang
        danach nicht mehr rückgängig gemacht werden kann.
        </p>
      </Modal>


      <Modal
        show={showEditModal}
        onCancel={cancelEditHandler}
        header="Liveticker bearbeiten"
        footerClass="ticker-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelEditHandler}>
              ZURÜCK
            </Button>
            <Button danger type="submit" onClick={confirmEditHandler}>
              BEARBEITEN
            </Button>
          </React.Fragment>
        }
      >
      <Input
        id="title"
        label="Titel"
        
        element="input"
        onInput={inputHandler}
        validators={[]}
        initialValue={props.title}
        initialValid={true}
      />
      <Input
        id="link"
        label="Link"
        
        element="input"
        onInput={inputHandler}
        validators={[]}
        initialValue={props.link}
        initialValid={true}
      />
      </Modal>

  <ListItem divider key={props.key} secondaryAction={
    <IconButton id={props.id} onClick={showDeleteWarningHandler} edge="end" aria-label="delete">
      <DeleteIcon />
    </IconButton>
  }
>
  <ListItemAvatar id={props.id} onClick={showEditHandler}>
    <Avatar>
      <EditIcon />
    </Avatar>
  </ListItemAvatar>
  <ListItemText
    primary={props.title}
    secondary={props.link && <><Link sx={{color:"#006400", fontWeight:"bold"}}underline="hover" target="_blank" rel="noopener" href={props.link}>Link</Link></>}
  />
 
  </ListItem>
  </>
}

export default TickerListItem