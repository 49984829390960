import React,{useEffect, useState, useContext}from 'react';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import './Dashboard.css';
import { styled } from '@mui/material/styles';
import { AuthContext} from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import TickerListItem from '../../ticker/components/TickerListItem';
import Input from "../../shared/components/FormElements/Input";
import {
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { Container, Box, Grid, Card, List, Avatar, Paper, Typography } from '@mui/material';
import PostThumbItem from '../../post/components/PostThumbItem';
import Button from '../../shared/components/FormElements/Button';
import Modal from '../../shared/components/UIElements/Modal';
import { useNavigate, Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#006400',
  padding: "0.5rem",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  color: "#fff",
  fontWeight:"bold",
  height:"90%",
  cursor:"pointer"
}));

const Dashboard = () => {
        const auth = useContext(AuthContext);
        const navigate = useNavigate()                
        const {isLoading, error, sendRequest, clearError} = useHttpClient(); 
        const [loadedSponsors, setLoadedSponsors] = useState();
        const [loadedTrainers, setLoadedTrainers] = useState();
        const [loadedPosts, setLoadedPosts] = useState();
        const [loadedTickers, setLoadedTickers] = useState();
        const [loadedTrainings, setLoadedTrainings] = useState();
        const [loadedTeams, setLoadedTeams] = useState();
        const [loadedPlayers, setLoadedPlayers] = useState();
        const [loadedDates, setLoadedDates] = useState();
        const [loadedLeads, setLoadedLeads] = useState();
        const [loadedData, setLoadedData] = useState(false);
        const [loadedUser, setLoadedUser] = useState();
        const [createTicker, setCreateTicker] = useState(false);

        const createTickers = (ticker, index) => {
            return<TickerListItem
              key={index}
              id={ticker.id}
              title={ticker.title}
              link={ticker.link}
              onDelete={deletedTickerHandler}

            />
        }
        const [formState, inputHandler] = useForm(
          {
            title: {
              value: "",
              isValid: false,
            },
            link: {
              value: "",
              isValid: true,
            },
          },
          false
        );
      

        
        useEffect(()=>{
                const fetchData = async () => {
                  const url = "https://api.djk-roden.de/api";
                    try{
                      setLoadedData(false);
                        const responseUser = await sendRequest(url + `/users/${auth.userId}`)
                        const responsePosts = await sendRequest(url +'/posts');
                        const responseTeams = await sendRequest(url +'/teams');
                        const responsePlayers = await sendRequest(url +'/players');
                        const responseTrainings = await sendRequest(url + '/trainings');
                        const responseTrainers = await sendRequest(url + '/trainers');
                        const responseDates = await sendRequest(url + '/dates');
                        const responseTickers = await sendRequest(url + '/tickers');
                        const responseSponsors = await sendRequest(url + '/sponsors');

                        //filter doubles
                        let allTrainers = [];
                        for(let i=0; i<responseTrainers.trainers.length; i++){
                          const trainerName = `${responseTrainers.trainers[i].prename} ${responseTrainers.trainers[i].name}`;
                          allTrainers.push(trainerName)
                        }
                        let realTrainerNumber = new Set(allTrainers)
                        realTrainerNumber = Array.from(realTrainerNumber)

                        let allPlayers = [];
                        for(let i=0; i<responsePlayers.players.length; i++){
                          const playerName = `${responsePlayers.players[i].prename} ${responsePlayers.players[i].name}`;
                          allPlayers.push(playerName)
                        }
                        let realPlayerNumber = new Set(allPlayers)
                        realPlayerNumber = Array.from(realPlayerNumber)
                    
                        setLoadedUser(responseUser.user)
                        setLoadedPosts(responsePosts.posts)
                        setLoadedTeams(responseTeams.teams)
                        setLoadedPlayers(realPlayerNumber)
                        setLoadedTrainings(responseTrainings.trainings)
                        setLoadedTrainers(realTrainerNumber)
                        setLoadedDates(responseDates.dates)
                        setLoadedTickers(responseTickers.tickers);
                        setLoadedSponsors(responseSponsors.sponsors)
                        // setLoadedLeads(responseLeads.leads);
                        setLoadedData(true);
                        
                        
                    }catch(err){
                            console.log(err)
            
                    }
                };
                fetchData();
            },[sendRequest, auth.userId]);

            
            const deletedTickerHandler = deletedTickerId => {
      
              setLoadedTickers(prevTickers => prevTickers.filter(ticker => ticker.id !== deletedTickerId));
            }

          

const confirmCreateHandler = async (event) => {
  event.preventDefault();
  try{
    const createTicker = await sendRequest(
      'https://api.djk-roden.de/api/tickers',
      'POST',
      JSON.stringify({
        title: formState.inputs.title.value,
        link: formState.inputs.link.value
      }),
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.token
      }
      );
      setLoadedTickers([...loadedTickers, createTicker.ticker]);

  }catch(err){}

}

if(!isLoading && !loadedUser)return <div>no user found</div>
if(isLoading)return<div style={{margin:"auto"}} className="center"><LoadingSpinner /></div>
    return (<React.Fragment>
         <ErrorModal error={error} onClear={clearError} />
         <Modal
        show={createTicker}
        onCancel={()=>setCreateTicker(false)}
        header="Liveticker bearbeiten"
        footerClass="ticker-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={()=>setCreateTicker(false)}>
              ZURÜCK
            </Button>
            <Button danger type="submit" onClick={confirmCreateHandler}>
              Erstellen
            </Button>
          </React.Fragment>
        }
      >
      <Input
        id="title"
        label="Titel"
        placeholder="Was gibt's neues?"
        element="input"
        onInput={inputHandler}
        validators={[]}
        
      />
      <Input
        id="link"
        label="Link"
        element="input"
        placeholder="Link zur Schaltfläche"
        onInput={inputHandler}
        validators={[]}
        
      />
      </Modal>

        <Container style={{margin:"4rem auto"}}>
{!isLoading && loadedData && <Box sx={{my:4}}>

<Grid container >
  <Grid item sm={10}><h1 style={{marginTop:0,marginBottom:0}}>Dashboard Übersicht</h1></Grid>
  <Grid item sm={2} sx={{alignItems:"center", justifyContent:"center"}}>
    <Card sx={{padding:"0.5rem"}}>
      <Grid container  spacing={2}>
      <Grid item sm={4}><Avatar>{loadedUser.name.slice(0,1).toUpperCase()}</Avatar></Grid>
      <Grid item sm={8} sx={{display:"flex", textAlign:"center", alignItems:"center"}} ><p style={{margin:0, paddingRight:"0.5rem"}}>{loadedUser.name}</p></Grid>
      </Grid>
    </Card>
  </Grid>
</Grid>

<Grid container spacing={1} sx={{margin:"2rem 0"}}>
  <Grid item  xs={12} sm={8} style={{textAlign:"left" }}>
    <Card style={{padding:"1rem"}}>
    <Grid spacing={1} container
         sx={{height:"100%"}}>
    <Grid item sm={6}>
    <h2>Latest Posts</h2>
    <Grid container spacing={2}>
    {loadedData && loadedPosts && loadedPosts.slice(0,4).map((p,i)=><Grid item sm={6}><PostThumbItem live route="posts" img={p.image} title={p.title} id={p.id} /></Grid>)}
    </Grid>
    </Grid>
    <Grid item sm={6}>
    <h2>Live Tickers</h2>
    <div>
    <List sx={{height:"208px", overflowY:"scroll"}} dense>
    {loadedData && loadedTickers && loadedTickers.map(createTickers)}
    </List>
    </div>
    <Button onClick={()=>setCreateTicker(true)}>Neuer Ticker</Button>
    </Grid>
    </Grid>
    </Card>
  </Grid>
  <Grid item xs={12} sm={4}>
  <Grid container spacing={1} >
    <Grid item sx={{aspectRatio:"1 / 1"}} xs={6}><Item onClick={()=>navigate('../dashboard/posts')}>Posts</Item></Grid>
    <Grid item sx={{aspectRatio:"1 / 1"}} xs={6}><Item onClick={()=>navigate('../dashboard/categories')}>Kategorien</Item></Grid>
    <Grid item sx={{aspectRatio:"1 / 1"}} xs={6}><Item onClick={()=>navigate('../dashboard/users')}>Users</Item></Grid>
    <Grid item sx={{aspectRatio:"1 / 1"}} xs={6}><Item onClick={()=>navigate('../dashboard/leads')}>Vorstand</Item></Grid>
  </Grid>
  </Grid>
  <Grid item sm={4} ><Card style={{padding:"1rem"}}>
  <h2>Sponsoren Übersicht</h2>
  <Grid container spacing={1} sx={{ height:"290px",overflowY:"scroll"}}>
  {loadedData && loadedSponsors && loadedSponsors.map((p,i)=><Grid item sm={6}><PostThumbItem route={"sponsors"} img={p.image} title={p.name} id={p.id} /></Grid>)}
  </Grid>
  </Card></Grid>

  <Grid item sm={8}>
  <Card style={{padding:"1rem"}}>

  <Grid container >
    <Grid item sm={12}>
    <h2>Teams Übersicht</h2>
    <Grid container spacing={1}>
    <Grid item xs={6} sm={2} style={{display:"flex",alignItems:"center",justifyContent:"center", color:"#006400",}}>
    <InfoOutlined fontSize='large'/>
    </Grid>
    <Grid item  xs={6} sm={2} style={{textAlign:"center", color:"#006400",}}>
    <Typography variant="h3">{loadedPlayers.length}</Typography>
      <Link  to={"../dashboard/players"}  style={{color:"#006400", cursor:"pointer", fontSize:"0.8rem", fontWeight:"bold"}} >Spieler</Link>
    </Grid>
    <Grid item xs={6}  sm={2} style={{textAlign:"center", color:"#006400",}}>
    <Typography variant="h3">{loadedTeams.length}</Typography>
      <Link  to={"../dashboard/teams"}  style={{color:"#006400", cursor:"pointer", fontSize:"0.8rem", fontWeight:"bold"}} >Teams</Link>
    </Grid>
    <Grid item  xs={6} sm={2} style={{textAlign:"center", color:"#006400",}}>
      <Typography variant="h3">{loadedTrainers.length}</Typography>
      <Link to={"../dashboard/trainers"}  style={{color:"#006400", cursor:"pointer", fontSize:"0.8rem", fontWeight:"bold"}}>Trainer</Link>
    </Grid>
    <Grid item  xs={6} sm={2} style={{textAlign:"center", color:"#006400",}}>
    <Typography variant="h3">{loadedPosts.filter(p => p.category.title === "Spielberichte").length}</Typography>
    <Link  to={"../dashboard/posts"}  style={{color:"#006400", cursor:"pointer", fontSize:"0.8rem", fontWeight:"bold"}}>Spielberichte</Link>
    </Grid>
    <Grid item  xs={6} sm={2} style={{textAlign:"center", color:"#006400",}}>
    <Typography variant="h3">{loadedDates.filter(d => d.category.title === "Spiel").length}</Typography>
    <Link to={"../dashboard/dates"} style={{color:"#006400", cursor:"pointer", fontSize:"0.8rem", fontWeight:"bold"}} >Termine</Link>
    </Grid>
    </Grid>
   </Grid>

    <h2>Angesagte Teams</h2>
    <Grid container spacing={1} sx={{ overflowY:"scroll"}}>
      {loadedTeams.sort((a,b)=> b.reports.length - a.reports.length).slice(0,4).map((t,i)=><Grid item sm={3}><PostThumbItem route={"teams"} live img={t.image} title={t.name} id={t.id} /></Grid>)}
    </Grid>
  </Grid>
  </Card>
</Grid>
</Grid>
</Box>
}
</Container>

</React.Fragment>)
}

export default Dashboard;