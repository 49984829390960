import React, {useState, useEffect} from "react";
import './PostSliderTopItem.css';
import Button from '../../shared/components/FormElements/Button';
import { useNavigate } from "react-router-dom";
import {useWindowSize} from '../../shared/hooks/size-hook';
import { Grid } from "@mui/material";
const PostSliderTopItem = (props) => {
  const navigate = useNavigate()

 const url = "https://api.djk-roden.de/uploads/images"
  const imgStyle={
    height:"100%",
    width:"100%",
    objectFit:"cover",
    filter: "contrast(1.25)",
    backgroundPosition:"center center",
  }
  const itemClickHandler = () =>{
    navigate(`/posts/${props.id}`);
  }
  
  const shortContent = props.content.split("\n")
  return (
    <Grid container direction={{xs:"row-reverse",sm:"row-reverse"}} className="slider-item">
      <Grid item md={8} className="slider-item__img-container" onClick={itemClickHandler} >
      <img src={url +  `/${props.image}`} alt={props.title} style={imgStyle} />
      </Grid>
      <Grid item md={4} px={6} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
        <h2 className="post-slider__item-headline" onClick={itemClickHandler}>{props.title.toUpperCase()}</h2>
        <p className="post-slider__item-text"> {shortContent[0]}</p>
        <div>
          <Button to={`/posts/${props.id}`}>MEHR</Button>
        </div>
        </Grid>

    </Grid>
  );
};

export default PostSliderTopItem;
