import * as React from 'react'
import { Switch } from '@mui/material'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import Button from '../../shared/components/FormElements/Button'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { AuthContext } from '../../shared/context/auth-context'

const PublishedSwitch = (props) => {
   const auth = React.useContext(AuthContext)
  const {isLoading, error, clearError, sendRequest} = useHttpClient()
  const {id, published} = props

  const publishBool = (published?.toLowerCase() === 'true');
  const [isPublished, setIsPublished] = React.useState(publishBool);
  const [showConfirmPublish, setShowConfirmPublish] = React.useState(false);

  const cancelPublishHandler = () => {
    setShowConfirmPublish(false);
    setIsPublished(publishBool);
  };
  const confirmPublishHandler = async () => {
    setShowConfirmPublish(false);
    try {
     const responseData = await sendRequest(
        `https://api.djk-roden.de/api/posts/publish/${id}`,
        'PATCH',
        JSON.stringify({
         published: isPublished.toString()
        }),
        { 'Content-Type': 'application/json', Authorization: 'Bearer ' + auth.token
      }
     
      );
      const responseBool = (responseData.post.published.toLowerCase() === 'true')
      console.log(responseBool);
      setIsPublished(responseBool);
      
    } catch (error) {
      
    }
  }

  const handlePublish = event => {
    setIsPublished(event.target.checked);
    setShowConfirmPublish(true);
  };

  return (<>
  <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner asOverlay/>}
    <Modal
      show={showConfirmPublish}
      onCancel={cancelPublishHandler}
      header="Bist du sicher?"
      footerClass="post-item__modal-actions"
      footer={
        <React.Fragment>
          <Button inverse onClick={cancelPublishHandler}>
            ZURÜCK
          </Button>
          <Button danger onClick={confirmPublishHandler}>
            PUBLISH
          </Button>
        </React.Fragment>
      }
    >

      <p>
      Möchtest du fortfahren und diesen Post veröffentlichen?
      </p>
    
    
    </Modal>
    <Switch checked={isPublished} onChange={handlePublish}  />
    </>
  )
}

export default PublishedSwitch