import React from 'react';
import Card from '../../shared/components/UIElements/Card';
import './PostGridItemLarge.css';
import {Link, useNavigate}from 'react-router-dom';
import { Divider, Grid } from '@mui/material';

const PostGridItemLarge = (props) => {
    const navigate = useNavigate();
    const navigateHandler = () => {
        navigate(`../posts/${props.id}`);
        document.location.reload();

    }
     

    const formatDate = d => {
        let oldDate = d;
        const year = oldDate.slice(2,4);
        const month = oldDate.slice(5,7);
        const day = oldDate.slice(8,10)
        return(
            `${day}.${month}.${year}`
        )

    }
    const url = "https://api.djk-roden.de/uploads/images"
    const DateString = props.date.slice(0, 11);
    const CardBGstyle = {
    backgroundImage: "url(" + url +  `/${props.image})`,
    backgroundSize: "cover",
    backgroundRepeat:"no-repeat",
    filter: "contrast(1.25)",
    backgroundPosition:"center center",
    borderRadius:"8px 8px 0 0",
    paddingTop:"66%"
    }

   
   
   
    const shortContent = props.content.split("\n")
    

    return(
        <Grid item sm={props.sm || 4} md={props.md || 4} lg={props.lg || 3}>
            <Card className="post-grid__card" >

            <div onClick={navigateHandler} className="post-grid__card-img"  style={CardBGstyle}></div>

            <div onClick={navigateHandler} className="post-grid__card-content-container">

            
                <h3 className="post-grid__card-title">{props.title}</h3>
                <p className="post-grid__card-content">{props.content}</p>
                
                <p className="post-grid__card-info">@{props.author} | <b>{props.category}</b></p>


            </div>
            <Divider sx={{marginTop:"auto"}}/>
            <div className="post-grid__card-footer">
            <Link reloadDocument to={`/posts/${props.id}`}>Mehr</Link>
            <span className="post-grid__card-date">{formatDate(DateString)}</span>
            </div>
            </Card>
        </Grid>
    )
}

export default PostGridItemLarge; 