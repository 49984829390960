import * as React from "react";

import 'react-calendar/dist/Calendar.css';
import { CSSTransition } from 'react-transition-group';
import { Link } from "react-router-dom";
import {useHttpClient} from '../../hooks/http-hook';
import ErrorModal from '../UIElements/ErrorModal';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import Calendar from 'react-calendar';
import { Icon } from "@iconify/react";
import './Calendar.css';


const createDate = (d) => {
            const givenDate = d;
            const splitDate = givenDate.split('.');
            const createdDate = splitDate[1] + '.' + splitDate[0] + '.' + splitDate[2];
            const formattedDate = new Date(createdDate).toLocaleDateString()
            return formattedDate;
}

const DateCalendar = (props) => {
    const {isLoading, error, clearError, sendRequest} = useHttpClient();
    const [value, setValue] = React.useState(new Date());
    const [loadedDates, setLoadedDates] = React.useState();

    const dateItems = (data, index) => {
        

        let dateColor;
        if( data.category.title === "Spiel"){
            dateColor = "#0000bb"
        }else if(data.category.title === "Aktion"){
            dateColor = "#008000"
        }else{
            dateColor = "#444444"
        }

        const dateStyle= {
            listStyle: "none",
            margin:"0.25rem 0",
            borderLeft:`2px solid ${dateColor}`
        }
       
        return <>
        <li key={index} style={dateStyle}>
            <div>
            {data.title === "Spieltermin" ? `${data.title.slice(0,5)} | ${data.team.name} ` : data.title + " "}<Icon icon="bxs:time" width="12" /> {data.time} Uhr
            </div>
           {data.home && <div>
            <Link to={`./mannschaften/info/${data.team.id}`} style={{display:"block", fontSize: "1rem", overflow: "hidden", textOverflow: "ellipsis" , whiteSpace: "nowrap"}} >{data.home} - {data.guest}</Link>
            </div>}
            {data.location && <div>
            <a style={{display:"block", fontSize: "1rem", overflow: "hidden", textOverflow: "ellipsis" , whiteSpace: "nowrap"}} target="blank" rel="noopener noreferrer" href={"https://www.google.com/maps/search/" + data.location} ><Icon icon="fa6-solid:location-dot" height="12" /> {data.location}</a>
            </div>}
            </li>
        </>
    }
    React.useEffect(()=>{
      
        const fetchDates = async () => {
           
            try{
                const responseDates = await sendRequest('https://api.djk-roden.de/api/dates');
                const sortedDates = responseDates.dates.sort((a,b) => a.time.localeCompare(b.time));
                setLoadedDates(sortedDates);
            }catch(e){}
            
        }
        
        fetchDates();

    },[sendRequest]);

    const gimmeChange = event => {
        
       setValue(new Date(event))
        
    }

    const handleTiles = ({activeStartDate, date, view}) => {
        let disabledTiles = [];
        
        for (const date of loadedDates){
            const createdDate = createDate(date.date);
            disabledTiles.push(createdDate);
        }
        
        if(disabledTiles.includes(date.toLocaleDateString())){
            return false;
        }else if(view !== "month"){
            return false;
        }else if(date.toLocaleDateString() === new Date().toLocaleDateString()){
            
            return false;
        }else{
            return true;
        }
       
    }
    

    
    return(<>
    {isLoading && <LoadingSpinner invisible />}
    <ErrorModal error={error} onClear={clearError}  />
       <CSSTransition
        in={props.show}
        timeout={300}
        classNames="calendar"
        unmountOnExit
        mountOnEnter
        >
        
        {loadedDates ? <div className='calendar-widget'>
            <span onClick={props.closeCal} className="close-cal">X</span>
            <Calendar tileDisabled={handleTiles} onChange={gimmeChange} value={value} />
            <hr/>
            <ul className="calendar-ul">
            {loadedDates?.filter(d => createDate(d.date) === new Date(value).toLocaleDateString()).map(dateItems)}
            {loadedDates?.filter(d => createDate(d.date) === new Date(value).toLocaleDateString()).length === 0 && <li key="empty">{new Date(value).toLocaleDateString() === new Date().toLocaleDateString() ? "Heute keine Termine" : "Keine Termine"}</li>}
            
            </ul>
        </div> : <div></div>}
        </CSSTransition>
    </>
    )
}

export default DateCalendar;
