import React from 'react';
import { Link, useNavigate} from 'react-router-dom';
import Card from '../../shared/components/UIElements/Card';
import './PostGridItemSmall.css';
import { Avatar, Divider, Grid } from '@mui/material';
const PostGridItemSmall = (props) => {
    const navigate = useNavigate();

    const navigateHandler = () => {
        navigate(`../posts/${props.id}`);
        document.location.reload();

    }
    const formatDate = d => {
        let oldDate = d;
        const year = oldDate.slice(2,4);
        const month = oldDate.slice(5,7);
        const day = oldDate.slice(8,10)
        return(
            `${day}.${month}.${year}`
        )

    }
    const DateString = props.date.slice(0, 11);


    return(
        <Grid item sm={12}>
            <Card onClick={navigateHandler} className="post-grid__card-short">



<Grid container spacing={1} style={{padding:"0.5rem"}}>
<Grid item xs={8}><h2 className="post-grid__card-title">{props.title}</h2>
</Grid>
<Grid item xs={4} style={{display:"flex",justifyContent:"flex-end"}}>
<Avatar src={`https://api.djk-roden.de/uploads/images/${props.image}`}  sx={{ width:75, height:75 }}/>
</Grid>
</Grid>

        
            <div style={{padding:"0 0.5rem"}} className="post-grid__cards-short__content-container">
            <p className="post-grid__card-info">@{props.author} | <b>{props.category}</b></p>
            </div>
            <Divider />
            <div style={{padding:"0.5rem",display:"flex", alignItems:"center", justifyContent:"space-between"}}>
            <Link reloadDocument to={`../posts/${props.id}`}>Mehr</Link>
            <span className="post-grid__card-date">{formatDate(DateString)}</span>
            </div>

            </Card>
        </Grid>
    )
}

export default PostGridItemSmall;