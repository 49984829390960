import * as React from 'react'
import { Switch } from '@mui/material'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import Button from '../../shared/components/FormElements/Button'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { AuthContext } from '../../shared/context/auth-context'

const HighlightedSwitch = (props) => {
   const auth = React.useContext(AuthContext)
  const {isLoading, error, clearError, sendRequest} = useHttpClient()
  const {id, highlighted} = props

  const highlightBool = (highlighted?.toLowerCase() === 'true');
  const [isHighlighted, setIsHighlighted] = React.useState(highlightBool);
  const [showConfirmHighlight, setShowConfirmHighlight] = React.useState(false);

  const cancelHighlightHandler = () => {
    setShowConfirmHighlight(false);
    setIsHighlighted(highlightBool);
  };
  const confirmHighlightHandler = async () => {
    setShowConfirmHighlight(false);
      try {
       const responseHighlight = await sendRequest(
          `https://api.djk-roden.de/api/posts/highlight/${id}`,
          'PATCH',
          JSON.stringify({
           highlighted: isHighlighted.toString()
          }),
          { 'Content-Type': 'application/json', Authorization: 'Bearer ' + auth.token
        }
       
        );
        const newBool = (responseHighlight.post.highlighted.toLowerCase() === 'true')
        setIsHighlighted(newBool);
        
      } catch (error) {
        
      }
  }

  const handleHighlight = event => {
    setIsHighlighted(event.target.checked);
    setShowConfirmHighlight(true);
  };
  
  return (<>
  <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner asOverlay/>}
    <Modal
      show={showConfirmHighlight}
      onCancel={cancelHighlightHandler}
      header="Bist du sicher?"
      footerClass="post-item__modal-actions"
      footer={
        <React.Fragment>
          <Button inverse onClick={cancelHighlightHandler}>
            ZURÜCK
          </Button>
          <Button danger onClick={confirmHighlightHandler}>
            HIGHLIGHT
          </Button>
        </React.Fragment>
      }
    >

      <p>
      Möchtest du fortfahren und diesen Post highlighten?
      </p>
    
    
    </Modal>
    <Switch checked={isHighlighted} onChange={handleHighlight}  />
    </>
  )
}

export default HighlightedSwitch