import React,{useState}from 'react';
import './NewsGrid.css';
import PostGridItemLarge from './PostGridItemLarge';
import Button from '../../shared/components/FormElements/Button';
import { Box, Grid, Stack, Fab, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ScrollTop(props) {
  const { children } = props;

  return (
    <Fade in={props.in}>
      <Box
        onClick={() =>  window.scrollTo(0,0)}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}


const NewsGrid = props => {
    const initialCount = 4;
    const [count, setCount] = useState(initialCount);
    const [filterState, setFilterState] = useState();
    const [outOfPosts,setOutOfPosts] = useState(false);

  

    const createCardsLG =(data,index)=>{
        return(<PostGridItemLarge
        key={index}
        content={data.content}
                date={data.createdAt}
                image={data.image}
                category={data.category.title}
                id={data.id}
                title={data.title}
                author={data.creator.name}
                sm={12}
                md={4}
                lg={3}
                borderless
        />
        )
    }
    const increaseCount = () =>{
      const postsCount = props.items.length;
      
      if(count <= postsCount){
        setCount(count+4);
      }else{
        setCount(count+4);
        setOutOfPosts(true);
      }
      
    }
  

    const handleAll = () => {
      setCount(initialCount);
      setOutOfPosts(false);
      setFilterState(false);
    }
    
    const handleFilters = e => {
      e.preventDefault();
      setCount(initialCount);
      setOutOfPosts(false);
      setFilterState(e.target.innerHTML);
    }

    const createFilter = (data, index) => {
      return(
        <Button key={index} inverse={filterState === data.title} type="button" onClick={handleFilters}>{data.title}</Button>
      )
    }

    return(<React.Fragment>

<Stack style={{padding:"1rem"}} direction="row" flexWrap="wrap" gap={1}>
       <Button inverse={!filterState} type="button"  onClick={handleAll}>Alle</Button>
      {props.filters.map(createFilter)}
     </Stack>

      <Grid style={{width:"100%"}} container alignItems="stretch" spacing={{xs:0,sm:0,md:2}}>
          {filterState && props.items.filter(post => post.category.title === filterState).slice(0,count).map(createCardsLG)}
          {!filterState && props.items.slice(0,count).sort((a,b) => a.index - b.index).map(createCardsLG)}
        </Grid>
       
   
        <ScrollTop in={count !== initialCount}>
        <Fab size="small" style={{color:"#006400"}} aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
     </ScrollTop>

     <div style={{margin:"1rem 0"}}> 
     <Button type="button" disabled={outOfPosts} onClick={increaseCount} >{outOfPosts ? "Das wars." : "Mehr Posts"}</Button>
     </div>
    </React.Fragment>)
}

export default NewsGrid;

