 import * as React from 'react';
 import './PostItem.css';
 import { useWindowSize } from '../../shared/hooks/size-hook';
 import {Link} from 'react-router-dom';
import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/FormElements/Button';
import hgsLogo from '../../shared/assets/PNG/hgs-logo.png';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PostTeams from '../../team/components/PostTeams';
 const PostItem = props => {

    const [isMobile, setIsMobile] = React.useState()

  const size = useWindowSize(true);
  React.useEffect(()=>{
    const checkMobile = ()=>{
      if(size.width > 420){
        setIsMobile(false);
      }else if(size.width < 420){
        setIsMobile(true);
      }
    }
    checkMobile();
  },[size]);

    const formatDate = d => {
        let oldDate = d;
        const year = oldDate.slice(0,4);
        const month = oldDate.slice(5,7);
        const day = oldDate.slice(8,10)
        return(
            `${day}.${month}.${year}`
        )

    }
    const createGallery = (data, index) => {

        const rowNumber = Math.floor(Math.random() * 3 + 1)
        
        return (
            <ImageListItem key={index}  rows={rowNumber}>
            <img
              className="gallery-image"
              src={`https://api.djk-roden.de/${data}?w=248&fit=crop&auto=format`}
              srcSet={`https://api.djk-roden.de/${data}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={`gallery ${index}`}
              loading="lazy"
            />
          </ImageListItem>
        )
    }
    return(
        <div id={props.id} className="post-item">
        <div className="post-item__header">
        <img src={ `https://api.djk-roden.de/uploads/images/${props.imageUrl}`} alt={props.title} />
        </div>
        <div className="post-item_content">
        <div className="post-item_content__inner">
        <h2>{props.title}</h2>

<p>{props.author} | <b>{props.category}</b> | {formatDate(props.date)} {props.team && '| ' + props.team.name }</p> 

<p className="post-content__paragraph" >{props.content}</p>
{props.link && <Button href={props.link}>Mehr</Button>}
        </div>
        <div className="post-item_content__inner">
        <div className="post-report-item">
        {props.category === "Spielberichte" && <h2>Übersicht</h2>}
       {props.category === "Spielberichte" && <Card id={props.reportId} className="report-card">
        
       <div className="report-stats">

{props.category === "Spielberichte" && props.homematch === "false" &&
<div><h5>{props.opponent}</h5></div>}

{props.category === "Spielberichte" && props.homematch === "true" &&
<div className="logo-container"><Link to={`../mannschaften/${props.team.status.toLowerCase()}/${props.team.id}`} ><img src={hgsLogo} alt="hgs" /></Link>
</div>}

<div className="report-stats__count">
    
    {props.category === "Spielberichte" && <div><h2> {props.eshome} : {props.esguest}</h2></div>}
    {props.category === "Spielberichte" && <div><p>({props.htshome} : {props.htsguest})</p></div>}
</div>
<div>
{props.category === "Spielberichte" && props.homematch === "true" && <div><h5>{props.opponent}</h5></div>}
{props.category === "Spielberichte" && props.homematch === "false" && <div className="logo-container"><Link to={`../mannschaften/${props.team.status.toLowerCase()}/${props.team.id}`} ><img src={hgsLogo} alt="hgs" /></Link></div>}

</div>

</div>
        </Card>}
        </div>
        </div>
       
      
        </div>
        <div className="post-item__gallery-container">
        <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={8}>
        {props.gallery && props.gallery.length > 0 && props.gallery.map(createGallery)}
</ImageList>
        </div>

        {props.teams.length > 0 && props.teams[0] !== null && <div className="post-item__teams">
            <PostTeams 
                items={props.teams}
            />
        </div>}
        </div>
        )
 }

 export default PostItem;